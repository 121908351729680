<template>
    <a-card>
        <a-page-header title='广告主销售目标城市管理' />
        <a-row type="flex" justify="start" :wrap="true">
            <a-col class="column-items">
                广告主状态：
                <a-select ref="select" v-model="searchParams.status" style="width: 200px">
                    <a-select-option :value="1">开启</a-select-option>
                    <a-select-option :value="2">关闭</a-select-option>
                </a-select>
            </a-col>
            <a-col class="column-items">
                广告主：
                <a-input style="width: 200px" v-model.trim="searchParams.advertiserName" placeholder="请输入广告主名称" allowClear
                    :maxLength="100">
                    {{ searchParams.advertiserName }}
                </a-input>
            </a-col>
            <a-col class="column-items"><a-button type="primary" style="width: 160px;" :loading="searchingLoading"
                    @click="handleSearch">查询</a-button></a-col>
        </a-row>

        <a-table bordered :loading="tableLoading" :columns="columns" :data-source="dataList"
            :row-key="(record, index) => index" :pagination='false'>
            <div slot="action" slot-scope="text, record">
                <a-button type="link" @click="handleEdit(record)">修改</a-button>
            </div>
        </a-table>

        <base-pagination :currentPage="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total"
            @onChange="handlePaginationChange" @onShowSizeChange="handlePaginationChange" />
    </a-card>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
    data() {
        const columns = [
            {
                align: 'center',
                title: '广告主',
                width: 200,
                dataIndex: 'advertiserName',
                customRender(text) {
                    return text || '-'
                },
            },
            {
                align: 'center',
                title: '广告主类型',
                width: 200,
                dataIndex: 'advertiserType',
                customRender(text) {
                    // 1:汽车经销商，2:汽车品牌
                    return ['汽车经销商', '汽车品牌商'][text - 1] || '-'
                },
            },
            {
                align: 'center',
                title: '目标城市',
                dataIndex: 'targetCityArr',
                customRender(text, record) {
                    const list = text?.map((v)=>(v.name)) || []
                    const nameText = list.length ?  list.reduce((prev, item) => {
                        prev += `、${item}`;
                        return prev;
                    }) : null
                    return nameText || '-'
                },
                ellipsis: true,
            },
            {
                align: 'center',
                title: '操作',
                key: 'action',
                width: 200,
                fixed: 'right',
                scopedSlots: { customRender: 'action' }
            }
        ]
        return {
            searchParams: {
                status: 1,
                advertiserName: undefined
            },
            searchingLoading: false,
            tableLoading: false,
            dataList: [],
            columns,
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        async getDataList() {
            this.tableLoading = true
            const params = Object.assign({}, this.searchParams, {
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
            const { data, code, message } = await this.$api.core.pphtManage.getCityManageList(params).finally(() => {
                this.tableLoading = false
            })
            if (code === 200) {
                this.dataList = data.list
                this.pagination.total = data.total || 0
            } else {
                this.$message.info(`${message}`)
            }
        },
        handleSearch: debounce(function () {
            this.pagination.page = 1
            this.getDataList()
        }, 1000, { 'leading': true }),
        handleEdit(data) {
            this.$router.push({ name: "pphtManage:cityList", query: { id: data.advertiserId } })
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        handleJumpManager() {
            this.$router.push({ name: 'pphtManage:advGroupList' })
        }
    }
}
</script>

<style scoped lang="less">
.column-items {
    margin: 0 0 20px 20px;
}
</style>